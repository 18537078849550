<template lang="pug">
    layout_page
        section.header.contact_header.font_smooth
            .bg.image
            .column.flex_content-vertical.ai_center.jc_center
                .structure
                .margin-bottom--md.anime.fadeInBottom.one
                    h1.text--xxxl.text--uppercase.text--ls-sm Acércate #[span &] Cotiza
                .anime.fadeInBottom.two
                    p.text--uppercase.text--ls-sm Cotiza tu proyecto con nosotros
            i.scroll_marker.anime.fadeInBottom.three
        section.contact
            .column
                .grid.grid-phi
                    div
                        .wrapper
                            .sec_title
                                span 01
                                h3 Contactanos
                            .margin-bottom--xxl
                                h2.text--xxxl Será un gusto atenderte
                            address.contact--section.margin-bottom--lg
                                strong.section--title Domicilio
                                p Av. Francisco I. Madero Ote. No. 5714-B, Ciudad Industrial Ampliación Sur, 58200 Morelia, Michoacán.
                            .contact--section.margin-bottom--lg
                                strong.section--title Teléfonos
                                p
                                    ul
                                        li
                                            a(href="tel:4433231835" alt="Teléfono")
                                                span (443) 323 1835
                                        li
                                            a(href="tel:4433234152" alt="Teléfono")
                                                span (443) 323 4152
                            .contact--section.margin-bottom--lg
                                strong.section--title Correo
                                p
                                    ul
                                        li
                                            a(href="mailto:contacto@lafragua.mx" alt="Correo Electrónico")
                                                span contacto@lafragua.mx
                            
                    div.flex_content-vertical.jc_center
                        .wrapper
                            GmapMap(
                                ref="googleMap"
                                :options="map.options"
                                :center="map.center"
                                :zoom="15"
                            )
                                GmapMarker(
                                    v-for="(marker, index) in map.markers"
                                    :key="index"
                                    :position="marker.position"
                                    @click="center=marker.position"
                                )
</template>

<script>
import Page from '@/views/layouts/Page'
import anime from 'animejs'
// import { gmapApi } from 'vue2-google-maps'

export default {
    name: 'page_contact',
    components: {
        layout_page: Page
    },
    data() {
        return {
            map: {
                center: {
                    lat: 19.723299,
                    lng: -101.141571
                },
                markers: [
                    {
                        position: {
                            lat: 19.723299,
                            lng: -101.141571
                        }
                    }
                ],
                icon: {
                    // url: "/static/img/marker.svg",
                    // anchor: new google.maps.Point(25,50),
                    // scaledSize: new google.maps.Size(50,50)
                },
                options: {
                    disableDefaultUI: true,
                    styles: [
                        {
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#bdbdbd"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dadada"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.station",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#c9c9c9"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        }
                    ]
                }
            }
        }
    },
    // computed: {
    //     google() {
    //         return gmapApi
    //     }
    // },
    methods: {
        initAnimations(curtain_shown) {
            let el = this.$el
            let timeline = anime.timeline()
            setTimeout(() => {
                if (!curtain_shown) {
                    timeline.add({
                        targets: el.querySelector('section.header'),
                        scale: [0.8, 1],
                        easing: 'easeOutExpo'
                    })
                }
                timeline
                .add({
                    targets: el.querySelector('.anime.fadeInBottom.one'),
                    translateY: [30, 0],
                    opacity: [0, 1],
                    easing: 'easeOutExpo'
                })
                .add({
                    targets: el.querySelector('.anime.fadeInBottom.two'),
                    translateY: [30, 0],
                    opacity: [0, 1],
                    easing: 'easeOutExpo'
                })
                .add({
                    targets: el.querySelector('.anime.fadeInBottom.three'),
                    translateY: [30, 0],
                    opacity: [0, 1],
                    easing: 'easeOutExpo'
                })
            }, curtain_shown ? 0 : 2400)
        }
    },
    metaInfo() {
        return {
            title: 'Contacto',
            meta:[
                {vmid: 'dtn', name:'description', content: 'Cotiza tu proyecto con nosotros.'},
                {vmid: 'kw', name:'keywords', content: 'La fragua, contacto, domicilio, teléfono, correo, email, ubicación, cotización, proyecto'},
                {vmid: 'inm', itemprop:'name', content: 'La Fragua | Contacto'},
                {vmid: 'idtn', itemprop:'description', content: 'Cotiza tu proyecto con nosotros.'},
                {vmid: 'ptt', property:'og:title', content: 'La Fragua | Contacto'},
                {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/contacto'},
                {vmid: 'pdtn', property:'og:description', content: 'Cotiza tu proyecto con nosotros.'},
                {vmid: 'tdtn', name:'twitter:description', content: 'Cotiza tu proyecto con nosotros.'},
                {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Contacto'}
            ]
        }
    },
    mounted() {
        this.initAnimations(this.$store.getters.isCurtainShown)
        this.$store.commit('desactiveCurtain')
    },
    watch: {
        '$route' (to, from) {
            if (to.name == 'page_contact') {
                this.initAnimations(true)
            }
        }
    }
}
</script>